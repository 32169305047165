
<template>
  <div class="flex bg-gray-100 items-center min-h-screen p-4 lg:justify-center">
    <div
      class="
        flex flex-col
        overflow-hidden
        bg-white
        rounded-md
        shadow-xl
        max
        md:flex-row md:flex-1
        lg:max-w-screen-md
      "
    >
      <div
        class="
          p-4
          py-6
          text-white
          bg-black
          md:w-80
          md:flex-shrink-0
          md:flex
          md:flex-col
          md:items-center
          md:justify-evenly
        "
      >
        <div class="my-3 text-4xl font-bold tracking-wider text-center">
          <img src="@/assets/logolight.png" class="p-10" />
        </div>

        <p class="mt-20 text-xs text-center text-gray-300">
          © Experglobal Trading Company, <br />All Rights Reserved
        </p>
      </div>
      <div class="p-6 bg-white md:flex-1">
        <h3 class="my-7 text-2xl font-semibold text-gray-700">
          Reset Password
        </h3>
        <form action="#" class="flex flex-col space-y-5">
          <div class="my-2">
            <router-link style="color: #cfb47e" to="/"
              >Back to login.</router-link
            >
          </div>
          <div class="flex flex-col space-y-1">
            <label for="email" class="text-sm font-semibold text-gray-500"
              >Password</label
            >
            <input
              v-model="user.password"
              type="password"
              id="email"
              autofocus
              :class="{ 'border-red-300': error }"
              class="
                px-4
                py-2
                transition
                duration-300
                border border-gray-300
                rounded
                focus:border-transparent
                focus:outline-none
                focus:ring-4
                focus:ring-blue-200
              "
            />
          </div>
          <div class="flex flex-col space-y-1">
            <div class="flex items-center justify-between">
              <label for="password" class="text-sm font-semibold text-gray-500"
                >Confirm Password</label
              >
            </div>
            <input
              v-model="user.confirm_password"
              type="password"
              id="password"
              :class="{ 'border-red-300': error }"
              class="
                px-4
                py-2
                transition
                duration-300
                border border-gray-300
                rounded
                focus:border-transparent
                focus:outline-none
                focus:ring-4
                focus:ring-blue-200
              "
            />
          </div>
          <div>
            <button
              @click.prevent="validate(user)"
              style="background-color: #cfb47e"
              type="submit"
              class="
                w-full
                px-4
                py-2
                text-lg
                font-semibold
                text-white
                transition-colors
                duration-300
                bg-blue-500
                rounded-md
                shadow
                hover:bg-blue-600
                focus:outline-none focus:ring-blue-200 focus:ring-4
              "
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  components: {},

  data() {
    return {
      user: {
        confirm_password: "",
        password: "",
        token: this.$route.query.token,
        id: this.$route.query.user,
      },
      error: false,
      options: {},
    };
  },
  mounted() {
    if (!this.$route.query.token) {
      this.$router.push("/");
    }
    if (!this.$route.query.user) {
      this.$router.push("/");
    }
  },
  computed: {},
  methods: {
    ...mapActions(["resetPassword"]),
    validate(user) {
      if (!this.user.password || !this.user.confirm_password) {
        this.error = true;
      }
      if (this.user.password !== this.user.confirm_password) {
        this.error = true;
      }

      if (
        this.user.password &&
        this.user.confirm_password &&
        this.user.password === this.user.confirm_password
      ) {
        this.error = false;
      }

      if (this.error) return false;
      else return this.resetPassword(user);
    },
  },
};
</script>



<style>
@media only screen and (min-width: 768px) {
  .video-wrapper {
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
  }

  .video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .video-title {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  .video-desc {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
}

.work-sans {
  font-family: "Work Sans", sans-serif;
}

#menu-toggle:checked + #menu {
  display: block;
}

.hover\:grow {
  transition: all 0.3s;
  transform: scale(1);
}

.hover\:grow:hover {
  transform: scale(1.02);
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
</style>




<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mobile-home {
    display: block;
  }

  .home {
    display: none;
  }

  .homeslide {
    margin-top: -50px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }

  .homeslide {
    margin-top: -140px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }

  .homeslide {
    margin-top: -140px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }
  .homeslide {
    margin-top: -10px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mobile-home {
    display: none;
  }

  .home {
    display: block;
  }
  .homeslide {
    margin-top: -150px;
  }
}

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(
    -60deg,
    rgba(213, 179, 118, 0.836) 50%,
    rgb(229, 205, 159) 50%
  );
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

.content {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  left: 50%;
  padding: 10vmin;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
</style>